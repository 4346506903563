/* ==========================================================================
   # START VARIABLE SETTING
   ==========================================================================
*/

$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1280px,
  xl: 1400px,
  xxl: 1600px,
);

$colors: (
  //new
  primary: #1968ff,
  text-desc: #98a2b3,
  text-title: #fcfcfd,
  text-title-2: #f2f4f7,
  text-suffix-input-active: #8cb4ff,
  text-suffix-input-inactive: #475467,
  text-purple: #a586fe,
  gray-25: #fcfcfd,
  gray-200: #fcfcfd,
  gray-300: #d0d5dd,
  gray-400: #98a2b3,
  gray-500: #667085,
  gray-600: #475467,
  gray-700: #344054,
  gray-800: #1d2939,
  gray-900: #101828,
  gray-950: #0a101a,
  warning-600: #dc6803,
  purple-600: #7f56d9,
  //
  background-grey: '#f2f2f2',
  text-color: '#2E260A',

  text-default: #cccccc,
  text-pagination: '#616161',
  border-primary: '#333333',
  green-primary: #30d158,
  error-primary: #e9453a,
  text-white: white,
  white: white,
  success-600:#039855
);

$fontSizes: (
  heading-block: (xs: 20px,
    sm: 20px,
    lg: 24px,
    xl: 24px,
  ),
  number-primary: (xs: 18px,
    sm: 18px,
    lg: 24px,
    xl: 24px,
  ),
);

$colors-dark: (
  primary: #ffc900,
  gray-900: #252525,
  gray-800: #1d1d1d,
);

:root {
  /*Begin color*/
  @each $name, $color in $colors {
    --color-#{$name}: #{$color};
  }

  /*End color*/

  /*Begin padding*/
  @each $name, $font-size in $fontSizes {
    // maps require a second loop
    @if type-of($font-size)=='map' {
      // each item in sub map
      @each $subname, $sub-font-size in $font-size {
        // --z-index-viewport-md
        --font-size-#{$name}-#{$subname}: #{$sub-font-size};
      }

      // top-level z-indexs
    }
    @else if type-of($font-size)=='number' {
      // --z-index-background
      --font-size-#{$name}: #{$font-size};
    }
  }

  /*End padding*/
}

[data-theme='dark'] {
  @each $name, $color in $colors-dark {
    --color-#{$name}: #{$color};
  }
}

//End variable root
/* ==========================================================================
   # END VARIABLE SETTING
   ==========================================================================
*/