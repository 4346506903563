.titan-pagination {
  display: flex;
  justify-content: space-between;
  &--action {
    display: flex;
    align-items: center;
    &-go:hover {
      cursor: pointer;
    }
  }
  .ant-pagination-item {
    width: 44px;
    height: 44px;
    background-color: color(gray-800);
    border: 1px solid transparent;
    color: color(text-pagination);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    @include respond-down(sm) {
      width: 32px;
      height: 32px;
    }

    a {
      display: flex;
      align-items: center;
      height: 44px;
      width: 43px;
      justify-content: center;
      padding: 0;
      color: color(text-pagination);
      font-size: 16px;
      font-weight: 600;
      @include respond-down(sm) {
        width: 32px;
        height: 31px;
        font-size: 14px;
      }
    }
    &:hover {
      border: 1px solid color(primary);
      a {
        color: color(primary);
      }
    }
    &.ant-pagination-item-active {
      border: 1px solid color(primary);
      color: color(primary);
      a {
        color: color(primary);
      }
    }
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: color(text-pagination);
  }
  &--select-size-change {
    .ant-select-selector {
      background-color: color(gray-800) !important;
      height: 44px !important;
      padding: 10px 12px !important;
      color: color(text-default);
      display: flex;
      align-items: center;
      border: 1px solid color(gray-700) !important;
    }
  }
  .titan-pagination--total {
    margin-left: 10px;
    .total-text {
      color: color(gray-400);
      margin-right: 5px;
    }
    .total-result {
      color: color(gray-25);
      margin-right: 5px;
    }
  }
  .ant-pagination-options-quick-jumper {
    height: 44px;
    display: flex;
    align-items: center;
    color: color(gray-400);
    >input {
      border: 1px solid color(gray-700) !important;
      height: 44px;
      background-color: color(gray-800) !important;
      text-align: center;
    }
  }
}

.popup-select-size-change {
  background-color: color(gray-800) !important;

  .ant-select-item {
    color: color(text-default);
    &:hover {
      background-color: color(primary);
      color: color(text-color);
    }
    &:first-child {
      border-radius: 8px 8px 0 0;
    }
    &:last-child {
      border-radius: 0 0 8px 8px;
    }
  }
  .ant-select-item-option-selected {
    background-color: transparent !important;
    color: color(primary);
    &:hover {
      color: color(primary);
    }
  }
  .ant-select-item-option-active {
    background-color: color(primary) !important;
    color: color(text-color) !important;
  }
}