.ant-breadcrumb>ol>li {
  display: flex;
  .titan-breadcrumb-seperator {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
.titan-breadcrumb {
  margin-bottom: 20px;

  &__link-old {
    font-size: 14px;
    color: color(gray-300);
    a {
      font-size: 14px;
      color: color(gray-300);
      text-decoration: none;
      &:hover {
        color: color(primary);
      }
    }
  }
  &__link-current {
    font-size: 14px;
    color: color(gray-500);
  }
}