@import './captcha';
@import './input';
@import './form';
@import './dialog';
@import './breadcrumb';
@import './table';
@import './tabs';
@import './pagination';
@import './chart';
@import './text';
@import './button';
@import './steps';
@import './picker';
@import './card';
@import './metamask';


.space-y-4 {
    >*+* {
        margin-top: 16px;
    }
}
.space-y-3 {
    >*+* {
        margin-top: 12px;
    }
}
.space-y-1 {
    >*+* {
        margin-top: 4px;
    }
}
.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}