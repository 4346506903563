.titan-tabs {
  margin-top: 24px;
  &.tabs-top-0 {
    margin-top: 0;
  }
  .ant-tabs-nav::before {
    border-bottom: 1px solid color(border-primary);
  }
  .ant-tabs-top {
    > .ant-tabs-nav::before {
      border-bottom: 1px solid color(border-primary);
    }
    .ant-tabs-nav {
      margin-bottom: 24px;
    }
  }
  .ant-tabs-tab {
    padding: 10px 32px;
    color: color(text-default);
    @include respond-down(sm) {
      padding: 10px 16px;
    }
    .ant-tabs-tab-btn {
      font-size: 16px;
      color: color(text-default);
    }
    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        font-weight: 700;
        color: color(primary);
      }
    }
  }
}
