.titan-page-dashboard {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }
    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      .code {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: color(gray-400);
      }
      >svg {
        cursor: pointer;
      }
    }
  }
  .filter-selected-items {
    display: flex;
    align-items: center;
    margin: 0px 10px;
    gap: 6px;
    &__label {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: color(gray-400);
    }
    &__option {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: right;
      color: color(gray-25);
    }
  }
  .card-item {
    padding: 16px !important;
    border-radius: 12px;
    border: 1px;
    gap: 20px;
    background: #1d2939;
    border: 1px solid #344054;
    &__title {
      >span {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #98a2b3;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__total-text {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      color: #fcfcfd;
      margin: 10px 0px;
      display: flex;
      align-items: center;
      gap: 10px;
      .growth-rate {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
      }
      .increase {
        color: #32d583;
        >svg {
          transform: rotate(180deg);
          >path {
            fill: #32d583;
          }
        }
      }
      .reduce {
        color: #f04438;
        >svg {
          >path {
            fill: #f04438;
          }
        }
      }
    }
    &__chart {
      border-radius: 8px;
      background: #000;
      min-height: 270px;
    }
    &__quick-filter {
      display: flex;
      justify-content: end;
      gap: 8px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      &-active {
        padding: 4px 8px;
        border-radius: 8px;
        background: color(gray-900);
        cursor: pointer;
      }
      &-inactive {
        padding: 4px 8px;
        border-radius: 8px;
        border: 1px solid color(gray-700);
        color: color(gray-600);
        cursor: pointer;
      }
    }

  }
}

.time-filter {
  display: flex;
  justify-content: end;
  gap: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  cursor: pointer;
  >div {
    height: fit-content;
  }
  &-active {
    padding: 4px 8px;
    border-radius: 8px;
    cursor: pointer;
    background: color(gray-900);
    &-color {
      background-color: #000;
      padding: 4px 8px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
  &-inactive {
    padding: 4px 8px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid color(gray-700);
    color: color(gray-600);
  }
}