.input-normal {
  .ant-input-suffix {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: color(gray-500);
    :hover {
      cursor: pointer;
    }
  }
  &.success {
    border: 1px solid color(green-primary) !important;
    .ant-input-suffix {
      color: color(green-primary);
    }
  }
  &.error {
    border: 1px solid color(error-primary) !important;
    .ant-input-suffix {
      color: color(error-primary);
    }
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}
.input-text-center {
  .ant-input {
    text-align: center;
  }
}
.input-normal::placeholder {
  color: color(gray-500) !important;
}

.ant-input-password {
  .ant-input::placeholder {
    color: color(gray-500) !important;
  }
}
.titan-select {
  border-radius: 8px;
  .ant-select-selector {
    background-color: color(gray-800) !important;
    height: 44px !important;
    padding: 10px 12px !important;
    color: color(text-default);
    display: flex;
    align-items: center;
    border: 1px solid color(gray-700) !important;
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      font-weight: 400;
      color: color(text-default) !important;
      font-size: 16px;
      margin-top: 1.5px;
      .flag,
      .icon {
        display: flex;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 8px;
        object-fit: cover;
      }
    }
  }
  &.success {
    border: 1px solid color(green-primary) !important;
    .ant-input-suffix {
      color: color(green-primary);
    }
  }
  &.error {
    border: 1px solid color(error-primary) !important;
    .ant-input-suffix {
      color: color(error-primary);
    }
  }
  .ant-select-selection-search-input {
    height: 44px !important;
  }
  &__popup {
    background-color: color(gray-800);
    padding: 0;
    border: 2px solid color(border-primary);
    z-index: 1000002;
    .ant-select-item {
      padding: 10px 12px !important;
      color: color(text-default);
      &:hover {
        background-color: color(primary);
        color: color(text-color);
      }
      &:first-child {
        border-radius: 8px 8px 0 0;
      }
      &:last-child {
        border-radius: 0 0 8px 8px;
      }
      &.option_dial_code {
        .ant-select-item-option-content {
          display: flex;
          align-items: center;
          .flag {
            margin-right: 8px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }
      &.option_icon {
        .ant-select-item-option-content {
          display: flex;
          align-items: center;
          .icon {
            display: flex;
            align-items: center;
            margin-right: 8px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }
    }
    .ant-select-item-option-selected {
      background-color: transparent !important;
      color: color(primary);
      &:hover {
        color: color(primary);
      }
    }
    .ant-select-item-option-active {
      background-color: color(primary) !important;
      color: color(text-color) !important;
    }
    .ant-select-item-empty {
      .ant-empty-description {
        color: white;
      }
    }
  }
}
.titan-input {
  &--search {
    padding: 6.5px 12px !important;
    background-color: color(gray-800) !important;
    border: 1px solid transparent !important;
    outline: none;
    box-shadow: none !important;
    height: 44px;
    .ant-input {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      background-color: transparent;
    }
    .ant-input::placeholder {
      color: color(gray-500) !important;
    }

    .ant-input-prefix {
      margin-right: 8px;
    }
    .ant-input-suffix {
      .ant-input-clear-icon {
        color: color(text-grey);
        font-size: 18px;
      }
    }
  }
}
textarea.ant-input::placeholder {
  color: color(gray-500) !important;
}
.input-otp-wrap {
  margin-top: 16px;
  margin-bottom: 16px;
  .otpContainer {
    justify-content: space-between;
    width: 100%;
    .input-otp {
      width: 44px;
      height: 56px;
      input {
        width: 100% !important;
        height: 100% !important;
        color: white;
        font-size: 30px;
        text-align: center;
        border-radius: 5px;
        background: color(gray-800-bold);
        border: 1px solid transparent !important;
        &:focus {
          outline: none !important;
          border: 1px solid color(primary) !important;
        }
      }
      &.error-code {
        input {
          border: 1px solid color(error-primary) !important;
        }
      }
    }
  }
}
.input-number {
  padding: 10px 12px;
  color: white;
  background: #1d1d1d;
  background: color(gray-800);
  border: 1px solid color(gray-800);
  box-shadow: none !important;
  width: 100%;
  border-radius: 8px;
  &.success {
    border: 1px solid color(green-primary) !important;
    .ant-input-suffix {
      color: color(green-primary);
    }
  }
  &.error {
    border: 1px solid color(error-primary) !important;
    .ant-input-suffix {
      color: color(error-primary);
    }
  }
}
.width-100 {
  width: 100%;
}

.group-radio {
  .ant-radio-group {
    display: flex;
    .ant-radio-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 40px;
      font-size: 16px;
      background-color: color(gray-800-bold);
      color: white;
      border: 1px solid transparent;
      &::before {
        background-color: transparent;
      }
      .ant-radio-button {
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      &.ant-radio-button-wrapper-checked {
        background-color: color(primary);
        color: color(text-color);
        .form-label {
          color: color(text-color);
        }
        .anticon {
          color: color(text-color);
        }
      }
    }
  }
}
.ant-tooltip-inner {
  background-color: black;
}
.ant-tooltip-arrow {
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: black;
  }
}
.titan-slider {
  .ant-slider {
    margin-top: 32px;
    margin-bottom: 62px;
    .ant-slider-mark {
      .ant-slider-mark-text {
        font-size: 14px;
        color: white;
        top: -42px;
      }
    }
    .ant-slider-rail {
      height: 8px;
      background-color: color(gray-700);
    }
    .ant-slider-track {
      height: 8px;
      background-color: color(primary);
    }
    .ant-slider-handle {
      width: 24px;
      height: 24px;
      margin-top: -8px;
      background-color: color(primary);
      border-color: color(primary);
    }
    .ant-slider-step {
      height: 8px;
      .ant-slider-dot {
        width: 12px;
        height: 12px;
        background-color: #4d4d4d;
        border: 2px solid #4d4d4d;
        &.ant-slider-dot-active {
          background-color: color(primary);
          border-color: color(primary);
        }
      }
    }
    .ant-tooltip-inner {
      background-color: transparent;
      border-radius: 40px;
      border: 1px solid color(primary);
      color: color(primary);
      padding: 4px 8px;
      min-height: 28px;
      .content-multiple-scale {
        font-size: 12px;
        font-weight: 500;
      }
    }
    .ant-tooltip-arrow {
      margin-top: -4px;
      width: 18px;
      height: 19px;
      .ant-tooltip-arrow-content {
        --antd-arrow-background-color: #fffbd3;
      }
    }
  }
}