.signin-page {
  display: flex;
  max-width: 900px;
  .signin-form {
    width: 450px;
    height: 420px;
    padding: 40px 32px 40px 32px;
    border: transparent solid 3px;
    background: linear-gradient(#101828 0 0) padding-box,
      linear-gradient(180.18deg, rgba(25, 104, 255, 0.5) 50%, rgba(25, 104, 255, 0) 90%) border-box;
    border-color: transparent;
    border-radius: 20px;
    &-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      color: color(text-title);
    }
    &-title-2 {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: color(text-title-2);
    }
    &-desc {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 20px;
      color: color(text-desc);
    }

    &-btn_submit {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      width: 100%;
      padding: 20px;
      border-radius: 12px;
      gap: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-forgot_text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: color(text-purple);
    }
    &-resend_active {
      color: color(text-suffix-input-active) !important;
    }
    &-resend_inactive {
      color: color(text-suffix-input-inactive) !important;
    }
  }

  .signin-content {
    width: 450px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    &-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: color(text-desc);
      padding: 30px 0px;
    }
    &-list {
      list-style-type: none;
      padding: 20px 0px;
      &-item {
        display: flex;
        align-items: center;
        padding: 7px 0px;
        & > svg {
          margin-right: 10px;
        }
      }
    }
  }
}
