.information-user-management-detail {
    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn-action {
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }
    &__content {
        margin-top: 12px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
        .box-3 {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
        .box-card-detail {
            height: 100%;
        }
    }
    &__footer {
        margin-top: 100px;
        .package-tracking-table {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        .flex-filter {
            display: flex;
            align-items: center;
            gap: 16px;
            .titan-input--search {
                max-width: 240px;
            }
        }
    }
}