.ant-input-affix-wrapper {
  padding: 10px 12px;
  box-shadow: none !important;
}
.ant-input {
  padding: 10px 12px;
  color: white;
  background: color(gray-800);
  border: 1px solid color(gray-800);
  box-shadow: none !important;

  @include input-placeholder {
    color: color(text-grey);
  }
  &:hover,
  &:focus {
    box-shadow: none;
  }
  &::placeholder {
    color: color(gray-500) !important;
  }
}
.ant-input-password-icon.anticon {
  color: color(text-grey);
  svg {
    path {
      fill: color(text-grey);
    }
  }
  &.anticon-eye {
    svg {
      path {
        &:nth-child(2) {
          fill: transparent;
        }
      }
    }
  }
  &:hover {
    color: color(text-grey);
  }
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid color(primary);
  box-shadow: none;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  background: #4d4d4d;
  border: 1px solid transparent;
  color: color(text-grey);
}

.btn-purple-ghost[disabled],
.btn-purple-ghost[disabled]:hover,
.btn-purple-ghost[disabled]:focus,
.btn-purple-ghost[disabled]:active {
  background: transparent;
  border: 1px solid color(gray-400);
  color: color(gray-400);
}

.ant-form-item {
  .ant-form-item-label {
    padding-bottom: 0;
    margin-bottom: 5px;
  }
  label {
    color: color(gray-400);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    margin-bottom: 4px;
  }
  .ant-input-affix-wrapper {
    border: 1px solid color(gray-800);
    background: color(gray-800);

    .ant-input {
      background: color(gray-800);
      color: white;
    }
  }
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background: color(gray-800);
    box-shadow: none;
  }
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background: color(gray-800);
  }
}
.ant-checkbox-inner {
  border-radius: 2px;
}
.ant-spin-nested-loading {
  .ant-spin-container {
    &::after {
      background: color(primary);
      border-radius: 8px;
    }
    &.ant-spin-blur {
      &::after {
        opacity: 0.05;
      }
    }
  }
}
.ant-btn {
  text-shadow: none !important;
  padding: 8px 14px;
  height: fit-content;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ant-modal-mask {
  z-index: 999;
}
.ant-modal-wrap {
  z-index: 1000;
}

.mr-16 {
  margin-right: 16px !important;
}
.ant-form-item-explain-error {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 7px 0px;
}

.ant-input-disabled {
  background-color: #4d4d4d !important;
  border: 1px solid #4d4d4d !important;
  color: color(text-grey) !important;
}
.ant-tooltip {
  z-index: 10000001;
}

.ant-select-selection-placeholder {
  text-align: left;
  color: color(gray-500);
}

.ant-table-column-sorters {
  justify-content: flex-start !important;
  .ant-table-column-title {
    flex: initial !important;
  }
  .ant-table-column-sorter {
    margin-left: 16px;
  }
}

.ant-table-tbody>tr.ant-table-row-selected>td {
  background-color: rgba(255, 254, 229, 0.1);
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #4d4d4d;
  border-color: #4d4d4d !important;
}

.ant-notification {
  z-index: 10000001;
  .ant-notification-notice {
    .anticon.ant-notification-notice-icon-success {
      color: color(green-primary);
    }
    background-color: color(gray-800);
    .ant-notification-notice-message {
      font-size: 16px;
      font-weight: 600;
      color: color(green-primary);
    }
    .ant-notification-notice-description,
    .ant-notification-notice-close {
      color: color(text-grey);
    }
  }
}

.add-option {
  color: #ffc900;
  font-weight: 600;
  font-size: 16px;
}

.add-option:hover {
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.ant-drawer-close {
  position: absolute;
  right: 0;
}
.ant-drawer-content {
  background-color: color(gray-900);
}
.ant-drawer-header {
  padding: 20px 20px 0 20px;
  border: none;
  .ant-drawer-header-title {
    align-items: flex-start;
  }
  .ant-drawer-title {
    color: color(text-white) !important;
    border-bottom: 1px solid color(gray-700);
    padding-bottom: 20px;
  }
}
.ant-drawer-body {
  color: color(text-white);
  padding: 20px;
  .titan-filter-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: color(gray-400);
  }
  >*+* {
    border-top: 1px solid #344054;
    padding-top: 12px;
  }
}

.ant-radio-checked .ant-radio-inner {
  border-color: #a586fe !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #a586fe;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #a586fe;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
  text-shadow: 0 0 0.25px currentcolor;
}
.ant-tabs-tab .ant-tabs-tab-btn {
  color: color(gray-500);
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-nav::before {
  border-bottom: 1px solid color(gray-700) !important;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: color(gray-25);
  pointer-events: none;
}

.ant-tabs-content {
  height: 100%;
}
.ant-tabs-tabpane {
  height: 100%;
}

.ant-message-notice-content {
  background-color: color(gray-800);
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: color(primary);
}