.statistic-card {
  padding: 20px 16px;
  background-image: url(../../assets/img/background-card.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  border-radius: 1.5rem;
  height: 100%;
  &__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  &__amount {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin: 10px 0px;
  }
  &__items {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.box-card-detail {
  padding: 16px;
  border-radius: 12px;
  border: 1px solid color(gray-700);
  background-color: color(gray-800);
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    border-bottom: 1px solid color(gray-700);
    .form-label {
      font-size: 16px;
      font-weight: 500 !important;
      line-height: 24px;
      color: color(gray-400);
    }
  }
  &__content {
    margin-top: 12px;
    >*+* {
      margin-top: 12px;
    }
    .row-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      .label {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: color(gray-400);
      }
      .value {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: color(gray-25);
        &.active {
          display: inline-flex;
          padding: 2px 8px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 100px;
          background: #039855;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
        &.inactive {
          display: inline-flex;
          padding: 2px 8px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 100px;
          background: #F04438;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
        &.pending {
          display: inline-flex;
          padding: 2px 8px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 100px;
          background: #DC6803;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
  }
}

.box-components {
  padding: 16px;
  border-radius: 12px;
  border: 1px solid color(gray-800);
  background-color: color(gray-900);
}

.card-background {
  padding: 20px 16px;
  background-image: url(../../assets/img/background-card.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 20px;
  .form-label {
    color: color(gray-25);
  }
}