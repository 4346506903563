.titan-steps-wrapper {
  display: flex;
  justify-content: center;
  @include respond-down(sm) {
    margin-top: 24px;
  }
  .titan-steps {
    width: fit-content;
    @include respond-down(md) {
      flex-direction: row;
      overflow-x: auto;
      @include customScrollTrack(4px, color(gray-900), 4px);
    }
    @include respond-down(sm) {
      flex-direction: row;
      overflow-x: auto;
    }
    .ant-steps-item {
      margin-left: 12px;
      margin-right: 12px;
      padding-left: 0;
      width: 100%;
      overflow: inherit;
      @include respond-down(md) {
        overflow: visible;
      }
      @include respond-down(sm) {
        width: fit-content;
        &:first-child {
          margin-left: 0;
        }
      }
      &::after {
        display: none;
      }
      &::before {
        left: 0;
        width: 100%;
        background-color: color(border-primary);
        @include respond-down(sm) {
          display: block;
        }
      }

      &.ant-steps-item-active {
        &::before {
          background-color: color(primary);
          animation-name: left-to-zero;
          animation-duration: 0.5s;
          @include respond-down(sm) {
            height: 2px;
            width: 100%;
            top: auto;
            bottom: 0;
          }
        }
      }
      .ant-steps-item-container {
        margin-left: 0;
        display: flex;
        .ant-steps-item-icon {
          order: 1;
          opacity: 0;
          display: flex;
          width: 16px;
          height: 16px;
          align-items: center;
          justify-content: center;
          margin-top: 4px;
        }
      }
      &.ant-steps-item-finish {
        &:hover {
          .ant-steps-item-container {
            .ant-steps-icon {
              color: color(text-color) !important;
            }
          }
        }
        .ant-steps-item-container {
          .ant-steps-item-icon {
            opacity: 1;

            background-color: color(primary);
            transition: ease all 0.4s;
          }
          .ant-steps-icon {
            font-size: 9px;
            color: color(text-color);
          }
        }
        .ant-steps-item-content {
          .ant-steps-item-title {
            color: white;
          }
          .ant-steps-item-description {
            color: color(text-default);
          }
        }
      }
      &.ant-steps-item-process {
        .ant-steps-item-content {
          .ant-steps-item-title {
            color: white;
          }
          .ant-steps-item-description {
            color: color(text-default);
          }
        }
      }
      .ant-steps-item-content {
        .ant-steps-item-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          text-transform: uppercase;
          color: color(text-grey);
        }
        .ant-steps-item-description {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: color(text-grey);
          white-space: nowrap;
          max-width: inherit !important;
        }
      }
    }
  }
}